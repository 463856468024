import { PartnerItem } from "@prmf/site-data";
import { useLoaderData } from "@remix-run/react";
import BgImage from "./BgImage";
import Carousel from "./Carousel";

type Props = {
  title?: string;
};

export default function PartnerTicker(props: Props) {
  const partners = useLoaderData<{ partners: PartnerItem }>().partners.items;

  return (
    <section className="container space-y-4 py-4">
      <h4 className="text-xm">{props.title || "Who we work with"}</h4>
      <Carousel
        className="container flex items-center justify-center gap-8"
        pagination={false}
      >
        {partners.map((img) => (
          <BgImage
            key={img}
            src={img}
            className="aspect-video w-[150px] rounded border"
          />
        ))}
      </Carousel>
    </section>
  );
}
